import request from '@/utils/request'
const userApi = {
  add: '/article/create',
  del: '/article/delete',
  detail: '/article/detail',
  list: '/article/page',
}
// 增
export function add (data) {
  return request({
    url: userApi.add,
    method: 'post',
    data: data
  })
}
// 删
export function del (parameter) {
  return request({
    url: userApi.del,
    method: 'get',
    params: parameter
  })
}
// 查 详情
export function detail (parameter) {
  return request({
    url: userApi.detail,
    method: 'get',
    params: parameter
  })
}
// 查 列表
export function list (parameter) {
  return request({
    url: userApi.list,
    method: 'get',
    params: parameter
  })
}
